import { Link, navigate } from "gatsby";
import React, { useEffect, useRef } from "react";
import navbarScrollEffect from "common/navbarScrollEffect";
import scrollToSection from "common/scrollToSection";

const OnePageNav = ({ location }) => {
  const navbarRef = useRef(null);

  useEffect(() => {
    navbarScrollEffect(navbarRef.current);
  }, [navbarRef]);

  useEffect(() => {
    const sections = document.querySelectorAll("[data-scroll-index]");

    window.addEventListener("scroll", () => {
      sections.forEach((section) => {
        const index = section.getAttribute("data-scroll-index");
        const offset = section.offsetTop;
        const height = section.offsetHeight;
        const scroll = window.scrollY;

        if (scroll + 200 > offset && scroll + 200 < offset + height) {
          document
            .querySelector(`[data-scroll-nav="${index}"]`)
            .classList.add("active");
        } else {
          // document.querySelector(`[data-scroll-nav="${index}"]`).classList.remove('active');
        }
      });
    });
  }, []);

  const handleNavigateToHome = (e) => {
    if (window.location.pathname !== "/") {
      navigate("/");
    }
    scrollToSection(e);
  };

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light style-1"
      ref={navbarRef}
    >
      <div className="container">
        <a className="navbar-brand" href="#" onClick={handleNavigateToHome}>
          <img src="/assets/img/logo.svg" data-scroll-nav="0" alt="" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item dropdown">
              <a
                className="nav-link"
                data-scroll-nav="1"
                onClick={handleNavigateToHome}
              >
                about us
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link"
                data-scroll-nav="2"
                onClick={handleNavigateToHome}
              >
                services
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-scroll-nav="3"
                onClick={handleNavigateToHome}
              >
                why us
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-scroll-nav="4"
                onClick={handleNavigateToHome}
              >
                portfolio
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-scroll-nav="5"
                onClick={handleNavigateToHome}
              >
                testimonials
              </a>
            </li>
            <li className="nav-item">
              <Link
                to="/outsource"
                className="nav-link"
                // data-scroll-nav="6"
                // onClick={scrollToSection}
              >
                Outsource
              </Link>
            </li>
          </ul>
          <div className="nav-side">
            <div className="hotline pe-4">
              <div className="icon me-3">
                <i className="bi bi-telephone"></i>
              </div>
              <div className="cont">
                <small className="text-muted m-0">Call 24/7</small>
                <h6>(+92) 346 555 4554</h6>
              </div>
            </div>
            <div className="qoute-nav ps-4">
              <a
                href="#0"
                className="btn sm-butn butn-gard border-0 text-white"
                data-scroll-nav="7"
              >
                <span>Meet us</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default OnePageNav;
