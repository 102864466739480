import React, { useEffect, useRef } from "react";
//= Scripts
import navbarScrollEffect from "common/navbarScrollEffect";
//= Layout
import MainLayout from "layouts/Main";
//= Components
import About from "components/Saas/About";
import Footer from "components/Digital/Footer";
import Services from "components/Digital/Services";
import Navbar from "components/Navbars/DigitalNav/OnePageNav";
import outsourceData from "data/Saas/outsourcing.json";

const PageServices5 = () => {
  const navbarRef = useRef(null);

  useEffect(() => {
    navbarScrollEffect(navbarRef.current);
  }, [navbarRef]);

  return (
    <MainLayout>
      <Navbar navbarRef={navbarRef} />
      <main className="services-page style-5">
        <About
          noPaddingTop
          title={outsourceData?.title}
          content={outsourceData?.texts}
        />
        <Services />
      </main>
      <Footer noWave />
    </MainLayout>
  );
};

export const Head = () => {
  return (
    <>
      <title>Outsourcing</title>
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  );
};

export default PageServices5;
